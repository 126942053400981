<template>
  <div class="chat-container">
    <div class="chat-title">
      <i class="hmyIconfont hmyIcon-fanhui return" @click="returnBtn"></i>
      <img :src="friend.avatar" class="chat-avatar"/>
      <div class="chat-name">{{ friend.name }}</div>
    </div>
    <div class="chat-main" ref="scrollView">
      <div class="message-list" ref="messageList">
        <div v-if="history.loading" class="history-loading">
          <img src="../assets/images/pending.gif"/>
        </div>
        <div v-else :class="history.allLoaded ? 'history-loaded':'load'" @click="loadHistoryMessage(false)">
          {{ history.allLoaded ? '已经没有更多的历史消息' : '获取历史消息' }}
        </div>
        <div v-for="(message, index) in history.messages" :key="index">
          <div class="time-tips">{{ renderMessageDate(message, index) }}</div>
          <div class="message-recalled" v-if="message.recalled">
            <div v-if="message.senderId !== currentUser.id">{{ friend.name }}撤回了一条消息</div>
            <div v-else class="message-recalled-self">
              <div>你撤回了一条消息</div>
              <span v-if="message.type === 'text' && Date.now()-message.timestamp< 60 * 1000 "
                    @click="editRecalledMessage(message.payload.text)">重新编辑</span>
            </div>
          </div>
          <div class="message-item" v-else>
            <div class="message-item-checkbox" v-if="messageSelector.visible && message.status !== 'sending'">
              <input class="input-checkbox" type="checkbox" :value="message.messageId" v-model="messageSelector.ids"
                     @click="selectMessages">
            </div>
            <div class="message-item-content" :class="{ self: message.senderId === currentUser.id }">
              <div class="sender-info">
                <img v-if="currentUser.id === message.senderId" :src="currentUser.avatar" class="sender-avatar"/>
                <img v-else :src="friend.avatar" class="sender-avatar"/>
              </div>
              <!--              <div class="message-content" @click.right="showActionPopup(message)">-->
              <div class="message-content" @contextmenu.prevent.stop="showActionPopup(message)">
                <div class="message-payload">
                  <div class="pending" v-if="message.status === 'sending'"></div>
                  <div class="send-fail" v-if="message.status === 'fail'"></div>
                  <div v-if="message.type === 'text'" class="content-text"
                       v-html="emoji.decoder.decode(message.payload.text)"></div>
                  <!--                  <div v-if="message.type === 'image'" class="content-image"-->
                  <!--                       @click="showImagePreviewPopup(message.payload.url)">-->
                  <div v-if="message.type === 'image'" class="content-image">
                    <el-image
                        :style="{height:getImageHeight(message.payload.width,message.payload.height)+'px', borderRadius: '8px' }"
                        :src="message.payload.url"
                        :preview-src-list="[message.payload.url]">
                    </el-image>
                    <!--                    <img :src="message.payload.url"-->
                    <!--                         :style="{height:getImageHeight(message.payload.width,message.payload.height)+'px'}"/>-->
                  </div>
                  <a v-if="message.type === 'file'" :href="message.payload.url" target="_blank" download="download">
                    <div class="content-file" title="点击下载">
                      <div class="file-info">
                        <span class="file-name">{{ message.payload.name }}</span>
                        <span class="file-size">{{ (message.payload.size / 1024).toFixed(2) }}KB</span>
                      </div>
                      <img class="file-img" src="../assets/images/file-icon.png"/>
                    </div>
                  </a>

                  <div v-if="message.type === 'order'" class="content-order">
                    <div class="order-id">订单号：{{ message.payload.id }}</div>
                    <div class="order-body">
                      <img :src="message.payload.url" class="order-img"/>
                      <div class="order-name">{{ message.payload.name }}</div>
                      <div>
                        <div class="order-price">{{ message.payload.price }}</div>
                        <div class="order-count">共{{ message.payload.count }}件</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="currentUser.id === message.senderId" :class="message.read ?'message-read':'message-unread'">
                  <div v-if="message.senderId === currentUser.id">{{ message.read ? '已读' : '未读' }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="chat-footer">
      <div class="action-delete" v-if="messageSelector.visible">
        <img class="delete-btn" src="../assets/images/delete.png" @click="deleteMultipleMessages"/>
        <div>删除</div>
      </div>
      <div class="action-box" v-else>
        <div class="action-bar">
          <!-- 表情 -->
          <div class="action-item">
            <div v-if="emoji.visible" class="emoji-box">
              <img
                  v-for="(emojiItem, emojiKey, index) in emoji.map"
                  class="emoji-item"
                  :key="index"
                  :src="emoji.url + emojiItem"
                  @click="chooseEmoji(emojiKey)"
              />
            </div>
            <i class="iconfont icon-smile" title="表情" @click="showEmojiBox"></i>
          </div>
          <!-- 图片 -->
          <div class="action-item">
            <label for="img-input">
              <i class="iconfont icon-picture" title="图片"></i>
            </label>
            <input v-show="false" id="img-input" accept="image/*" multiple type="file"
                   @change="sendImageMessage"/>
          </div>

          <!-- 文件 -->
          <div class="action-item">
            <label for="file-input">
              <i class="iconfont icon-wj-wjj" title="文件"></i>
            </label>
            <input v-show="false" id="file-input" type="file"
                   @change="sendFileMessage"/>
          </div>
          <!-- 自定义 -->
          <!--          <div class="action-item" v-if="this.friend.id=='ai'">-->
          <!--            <i class="iconfont icon-liebiao" title="订单" @click="showOrderMessageList"></i>-->
          <!--            <i class="iconfont icon-liebiao" title="更多"></i>-->
          <!--          </div>-->
        </div>

        <div class="input-box">
        <textarea ref="input" @focus="onInputFocus" @keyup.enter="sendTextMessage" @keyup.shift.enter="nextLine"
                  v-model="text" maxlength="700"
                  autocomplete="off" class="input-content" placeholder="请输入（Shift + Enter换行）"></textarea>
        </div>
        <div class="send-box">
          <button class="send-button" @click="sendTextMessage" v-bind:disabled="sendButtonIsDisabled">{{
              sendButtonText
            }}
          </button>
        </div>
      </div>
    </div>

    <div v-if="imagePreview.visible" class="image-preview">
      <img :src="imagePreview.url" alt="图片"/>
      <span class="close" @click="hideImagePreviewPopup">×</span>
    </div>
    <!-- 消息删除撤回弹窗 -->
    <div class="action-popup" v-if="actionPopup.visible" @click="actionPopup.visible = false">
      <div class="action-popup-main">
        <div class="action-item" @click="deleteSingleMessage">删除</div>
        <div class="action-item" v-if="actionPopup.recallable" @click="recallMessage">撤回</div>
        <div class="action-item" @click="showCheckBox">多选</div>
        <div class="action-item" @click="actionPopup.visible = false">取消</div>
      </div>
    </div>
  </div>
</template>

<script>
import {formatDate} from '../utils/utils.js'
import EmojiDecoder from '../utils/EmojiDecoder';
import emoji from "../utils/emoji";

const IMAGE_MAX_WIDTH = 200;
const IMAGE_MAX_HEIGHT = 150;
export default {
  name: 'PrivateChat',
  components: {},
  data() {
    const emojiUrl = 'https://imgcache.qq.com/open/qcloud/tim/assets/emoji/';
    const emojiMap = emoji.emojiMap;

    return {
      currentUser: null,
      friend: null,

      history: {
        messages: [],
        allLoaded: false,
        loading: true
      },

      text: '',

      //定义表情列表
      emoji: {
        url: emojiUrl,
        map: emojiMap,
        visible: false,
        decoder: new EmojiDecoder(emojiUrl, emojiMap),
      },
      // 图片预览弹出框
      imagePreview: {
        visible: false,
        url: ''
      },
      // 展示消息删除弹出框
      actionPopup: {
        visible: false,
        message: null,
        recallable: false,
      },
      // 消息选择
      messageSelector: {
        visible: false,
        ids: []
      },
      sendButtonIsDisabled: false,
      sendButtonText: "发送"
    };
  },
  created() {
    this.friend = {
      id: this.$route.params.id,
      name: this.$route.query.name,
      avatar: this.$route.query.avatar,
    };
    this.currentUser = this.globalData.currentUser;

    this.loadHistoryMessage(true);

    this.$store.state.webSocketMethod.onReceivedPrivateMessage = this.onReceivedPrivateMessage;
    this.$store.state.webSocketMethod.onPrivateMessageUpdate = this.updatePrivateMessage;


  },
  mounted() {
  },
  beforeDestroy() {
    this.$store.state.webSocketMethod.onReceivedPrivateMessage = null;
    this.$store.state.webSocketMethod.onPrivateMessageUpdate = null;

  },
  methods: {
    formatDate,
    onReceivedPrivateMessage(message) {
      if (message.senderId === this.friend.id || (message.senderId === this.currentUser.id && message.receiverId === this.friend.id)) {
        this.history.messages.push(message);
        this.markPrivateMessageAsRead();
      }
      this.scrollToBottom();
    },
    updatePrivateMessage(message) {
      if (message.senderId === this.friend.id || (message.senderId === this.currentUser.id && message.receiverId === this.friend.id)) {
        if (message.type === 'read') {
          this.history.messages.forEach(msg => {
            if (msg.read === false)
              msg.read = true;
          })
        } else if (message.type === 'recall') {
          this.history.messages.find(msg => msg.messageId === message.messageId).recalled = true;
        }
      }


    },
    /**
     * 核心就是设置高度，产生明确占位
     * 小  (宽度和高度都小于预设尺寸)
     *    设高=原始高度
     * 宽 (宽度>高度)
     *    高度= 根据宽度等比缩放
     * 窄  (宽度<高度)或方(宽度=高度)
     *    设高=MAX height
     * @param width,height
     * @returns number
     */
    getImageHeight(width, height) {
      if (width < IMAGE_MAX_WIDTH && height < IMAGE_MAX_HEIGHT) {
        return height;
      } else if (width > height) {
        return IMAGE_MAX_WIDTH / width * height;
      } else if (width === height || width < height) {
        return IMAGE_MAX_HEIGHT;
      }
    },

    sendTextMessage(event) {
      if (event.shiftKey) {
        return;
      }
      if (!this.text.trim()) {
        console.log('输入为空');
        return
      }

      let privateMessage = {
        receiverId: this.friend.id,
        senderId: this.currentUser.id,
        payload: {
          text: this.text,
        },
        timestamp: Date.now(),
        type: 'text'
      }
      let message = {
        type: 'privateMessage',
        privateMessage: privateMessage,
      }
      this.$store.state.webSocket.send(JSON.stringify(message));
      this.text = '';
    },
    onInputFocus() {
      this.emoji.visible = false;
    },
    showEmojiBox() {
      this.emoji.visible = !this.emoji.visible;
    },
    chooseEmoji(emojiKey) {
      this.text += emojiKey;
      this.emoji.visible = false;
    },
    // sendImageMessage(e) {
    //   let fileList = [...e.target.files];
    //   fileList.forEach((file) => {
    //     let formData = new FormData();
    //     formData.append('image', file);
    //
    //     this.$http.post("/upload/image", formData, {
    //       "Content-Type": "multipart/form-data;charset=utf-8"
    //     }).then(res => {
    //       let url = res.data.data;
    //
    //       let img = url;
    //       let imgObj = new Image();
    //       imgObj.src = img;
    //
    //       let _this = this
    //       imgObj.onload = function () {
    //         let privateMessage = {
    //           receiverId: _this.friend.id,
    //           senderId: _this.currentUser.id,
    //           payload: {
    //             name: file.name,
    //             // contentType: 'image/jpeg',
    //             url: url,
    //             width: imgObj.width,
    //             height: imgObj.height,
    //             size: file.size,
    //           },
    //           timestamp: Date.now(),
    //           type: 'image'
    //         }
    //         let message = {
    //           type: 'privateMessage',
    //           privateMessage: privateMessage,
    //         }
    //         _this.$store.state.webSocket.send(JSON.stringify(message));
    //       };
    //
    //     })
    //   })
    // },

    sendImageMessage(e) {
      let fileList = [...e.target.files];
      fileList.forEach((file) => {
        let formData = new FormData();
        formData.append('image', file);

        let reader = new FileReader();
        reader.readAsDataURL(file);
        let width=null;
        let height=null;
        let _this = this

        // 监听读取完成事件
        reader.onload = function () {
          // 创建一个新的 Image 对象
          let img = new Image();

          // 设置图片的 src 属性为数据 URL
          img.src = reader.result;


          // 监听图片加载完成事件
          img.onload = function () {
            // 获取图片的宽度和高度
            width = this.naturalWidth;
            height = this.naturalHeight;
            console.log(width)
            _this.$http.post("/upload/image", formData, {
              "Content-Type": "multipart/form-data;charset=utf-8"
            }).then(res => {
              let url = res.data.data;

              let privateMessage = {
                receiverId: _this.friend.id,
                senderId: _this.currentUser.id,
                payload: {
                  name: file.name,
                  // contentType: 'image/jpeg',
                  url: url,
                  width: width,
                  height: height,
                  size: file.size,
                },
                timestamp: Date.now(),
                type: 'image'
              }
              let message = {
                type: 'privateMessage',
                privateMessage: privateMessage,
              }
              _this.$store.state.webSocket.send(JSON.stringify(message));
            })

          };
        };
      })
    },

    sendFileMessage(e) {
      const file = e.target.files[0];

      let formData = new FormData();
      formData.append('file', file);

      this.$http.post("/upload/file", formData, {
        "Content-Type": "multipart/form-data;charset=utf-8"
      }).then(res => {
        // let url = this.baseURL + '/uploadfile/file/' + res.data.data;
        // console.log(url)

        let privateMessage = {
          receiverId: this.friend.id,
          senderId: this.currentUser.id,
          payload: {
            name: file.name,
            // contentType: 'application/pdf',
            url: res.data.data,
            size: file.size,
          },
          timestamp: Date.now(),
          type: 'file'
        }
        let message = {
          type: 'privateMessage',
          privateMessage: privateMessage,
        }
        this.$store.state.webSocket.send(JSON.stringify(message));


      })

    },

    showActionPopup(message) {
      const MAX_RECALLABLE_TIME = 3 * 60 * 1000; //3分钟以内的消息才可以撤回
      this.messageSelector.ids = [message.messageId];
      if ((Date.now() - message.timestamp) < MAX_RECALLABLE_TIME && message.senderId === this.currentUser.id && message.status === 'success') {
        this.actionPopup.recallable = true;
      } else {
        this.actionPopup.recallable = false;
      }
      this.actionPopup.visible = true;
    },
    deleteSingleMessage() {
      this.actionPopup.visible = false;
      this.deleteMessage();
    },
    deleteMultipleMessages() {
      if (this.messageSelector.ids.length > 0) {
        this.deleteMessage();
      }
      this.messageSelector.visible = false;
    },
    deleteMessage() {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let selectedMessages = [];
        this.history.messages.forEach((message) => {
          if (this.messageSelector.ids.includes(message.messageId)) {
            selectedMessages.push(message);
          }
        });

        this.$http.put('/privatemessage/deletemessage/' + this.currentUser.id, selectedMessages).then((res) => {
          if (res.data === true) {
            selectedMessages.forEach((message) => {
              let index = this.history.messages.indexOf(message);
              if (index > -1) {
                this.history.messages.splice(index, 1);
              }
            });
            this.messageSelector.ids = [];
            this.$store.state.webSocketMethod.onConversationsUpdate();
          } else {
            console.log('删除失败');
          }
        })
      }).catch(() => {
        this.messageSelector.ids = [];
      });
    },
    recallMessage() {
      let selectedMessages = [];
      this.history.messages.forEach((message) => {
        if (this.messageSelector.ids.includes(message.messageId)) {
          selectedMessages.push(message);
        }
      });
      this.actionPopup.visible = false;

      this.$http.put('/privatemessage/recallmessage', selectedMessages).then((res) => {
        if (res.data === true) {
          console.log('撤回成功');
        } else {
          console.log('撤回失败');
        }
      });
    },
    editRecalledMessage(text) {
      this.text = text;
    },
    showImagePreviewPopup(url) {
      this.imagePreview.visible = true;
      this.imagePreview.url = url;
    },
    hideImagePreviewPopup() {
      this.imagePreview.visible = false;
    },
    showCheckBox() {
      this.messageSelector.ids = [];
      this.messageSelector.visible = true;
      this.actionPopup.visible = false;
    },
    selectMessages(e) {
      if (e.target.checked) {
        this.messageSelector.ids.push(e.target.value)
      } else {
        let index = this.messageSelector.ids.indexOf(e.target.value);
        if (index > -1) {
          this.messageSelector.ids.splice(index, 1);
        }
      }
    },
    loadHistoryMessage(scrollToBottom) {
      this.history.loading = true;
      //历史消息
      let lastMessageTimeStamp = Date.now();
      let lastMessage = this.history.messages[0];
      if (lastMessage) {
        lastMessageTimeStamp = lastMessage.timestamp;
      }

      this.$http.get('/privatemessage/history/' + this.currentUser.id + '/' + this.friend.id + '/' + lastMessageTimeStamp)
          .then((result) => {
            this.history.loading = false;
            let messages = result.data;
            if (messages.length === 0) {
              this.history.allLoaded = true;
            } else {
              this.history.messages = messages.concat(this.history.messages);

              if (messages.length < 10) {
                this.history.allLoaded = true;
              }

              if (scrollToBottom) {
                this.scrollToBottom();
                //收到的消息设置为已读
                this.markPrivateMessageAsRead();

              }
            }
          });
    },
    markPrivateMessageAsRead() {
      this.$http.put('/privatemessage/markasread', {userId: this.currentUser.id, friendId: this.friend.id})
          .then((res) => {
            if (res.data === true) {
              console.log('标记私聊已读成功');
            } else {
              console.log('标记私聊已读失败');
            }
            this.$store.state.webSocketMethod.onConversationsUpdate();
          })
    },
    scrollToBottom() {
      this.$nextTick(() => {
        if (this.$refs.messageList.scrollHeight === 0) {
          setTimeout(() => {
            this.$refs.scrollView.scrollTop = this.$refs.messageList.scrollHeight;
          }, 10);
        } else {
          this.$refs.scrollView.scrollTop = this.$refs.messageList.scrollHeight;
        }

        // this.$refs.scrollView.scrollTop = this.$refs.messageList.scrollHeight;
      });
    },
    renderMessageDate(message, index) {
      if (index === 0) {
        return this.formatDate(message.timestamp);
      } else {
        if (message.timestamp - this.history.messages[index - 1].timestamp > 5 * 60 * 1000) {
          return this.formatDate(message.timestamp);
        }
      }
      return '';
    },
    returnBtn() {
      this.$router.replace({path: '/conversations'});
    },
    nextLine() {
      this.text += '\n';
    },
  },
};
</script>

<style scoped>
.chat-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.chat-title {
  height: 40px;
  padding: 5px 15px 5px 15px;
  display: flex;
  align-items: center;
  font-size: 18px;
  border-bottom: 1px solid #dcdfe6;
}

.return {
  font-size: 20px;
  display: none;
  margin-right: 10px;
}

.return:hover {
  color: #1E88E5;
}

.chat-avatar {
  width: 35px;
  height: 35px;
}

.chat-name {
  /*width: 200px;*/
  font-size: 15px;
  margin-left: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
}

.chat-main {
  padding-top: 5px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  flex: 1;
  scrollbar-width: thin;
}

.chat-main::-webkit-scrollbar {
  width: 0;
}

.chat-main .history-loaded {
  text-align: center;
  font-size: 12px;
  color: #cccccc;
  line-height: 20px;
}

.chat-main .load {
  text-align: center;
  font-size: 12px;
  color: #1E88E5;
  line-height: 20px;
  cursor: pointer;
}

.history-loading {
  width: 100%;
  text-align: center;
}

.time-tips {
  color: #999;
  text-align: center;
  font-size: 12px;
}

.message-list {
  padding: 0 10px;
}

.message-item {
  display: flex;
}

.message-item-checkbox {
  height: 50px;
  margin-right: 15px;
  display: flex;
  align-items: center;
}

.input-checkbox {
  position: relative;
}

.message-item-checkbox input[type="checkbox"]::before, .message-item-checkbox input[type="checkbox"]:checked::before {
  content: "";
  position: absolute;
  top: -3px;
  left: -3px;
  background: #FFFFFF;
  width: 18px;
  height: 18px;
  border: 1px solid #cccccc;
  border-radius: 50%;
}

.message-item-checkbox input[type="checkbox"]:checked::before {
  content: "\2713";
  background-color: #1E88E5;
  width: 18px;
  color: #FFFFFF;
  text-align: center;
  font-weight: bold;
}

.message-item-content {
  flex: 1;
  margin: 5px 0;
  overflow: hidden;
  display: flex;
}

.sender-info {
  margin: 0 5px;
}

.sender-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.message-content {
  max-width: calc(100% - 100px);
}

.message-payload {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.pending {
  background: url("../assets/images/pending.gif") no-repeat center;
  background-size: 13px;
  width: 15px;
  height: 15px;
}

.send-fail {
  background: url("../assets/images/failed.png") no-repeat center;
  background-size: 15px;
  width: 18px;
  height: 18px;
  margin-right: 3px;
}

.message-read {
  color: gray;
  font-size: 12px;
  text-align: end;
  height: 16px;
}

.message-unread {
  color: #F56C6C;
  font-size: 12px;
  text-align: end;
  height: 16px;
}

.content-text {
  display: flex;
  align-items: center;
  text-align: left;
  background: #f2f2f2;
  font-size: 14px;
  font-weight: 500;
  padding: 6px 8px;
  margin: 3px 0;
  line-height: 25px;
  white-space: pre-line;
  overflow-wrap: anywhere;
  border-radius: 8px;
  word-break: break-all;
}

.content-image {
  display: block;
  cursor: pointer;
}

.content-file {
  width: 240px;
  height: 65px;
  font-size: 15px;
  background: #FFFFFF;
  border: 1px solid #eeeeee;
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.content-file:hover {
  background: #f1f1f1;
}

.file-info {
  width: 194px;
  text-align: left;
}

.file-name {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  word-break: break-all;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.file-size {
  font-size: 12px;
  color: #ccc;
}

.file-img {
  width: 50px;
  height: 50px;
}

.message-item .self {
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
  flex-direction: row-reverse;
}

.message-item .self .content-text {
  background: #58a8ff;
  color: #FFFFFF;
}

.message-item .self .play-icon {
  background: url("../assets/images/play.gif") no-repeat center;
  background-size: 20px;
  -moz-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.message-recalled {
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 28px;
  font-size: 13px;
  text-align: center;
  color: grey;
  margin-top: 10px;
}

.message-recalled-self {
  display: flex;
}

.message-recalled-self span {
  margin-left: 5px;
  color: #1E88E5;
  cursor: pointer;
}

.chat-footer {
  border-top: 1px solid #dcdfe6;
  width: 100%;
  height: 140px;
  background: #FFFFFF;
}

.action-delete {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #FFFFFF;
}

.delete-btn {
  width: 25px;
  height: 25px;
  padding: 10px;
  background: #f5f5f5;
  border-radius: 50%;
  cursor: pointer;
  margin-bottom: 10px;
}

.action-box {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.action-bar {
  display: flex;
  flex-direction: row;
  padding: 0 10px;
}

.action-bar .action-item {
  text-align: left;
  padding: 10px 0;
  position: relative;
}

.action-bar .action-item .iconfont {
  font-size: 22px;
  margin: 0 10px;
  z-index: 3;
  color: #606266;
  cursor: pointer;
}

.action-bar .action-item .iconfont:focus {
  outline: none;
}

.action-bar .action-item .iconfont:hover {
  color: #1E88E5;
}

.emoji-box {
  width: 340px;
  height: 200px;
  overflow: auto;
  scrollbar-width: thin;
  position: absolute;
  top: -240px;
  z-index: 2007;
  background: #fff;
  border: 1px solid #ebeef5;
  padding: 12px;
  text-align: justify;
  font-size: 14px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  word-break: break-all;
  border-radius: 4px;
}

.emoji-box::-webkit-scrollbar {
  width: 0;
}

.emoji-item {
  width: 30px;
  height: 30px;
  margin: 0 2px;
}

.input-box {
  padding: 0 10px;
  flex: 1;
}

.input-content {
  border: none;
  resize: none;
  display: block;
  padding: 5px 15px;
  box-sizing: border-box;
  width: 100%;
  color: #606266;
  outline: none;
  background: #FFFFFF;
  word-break: break-all;
}

.send-box {
  padding: 5px 10px;
  text-align: right;
}

.send-button {
  width: 70px;
  height: 30px;
  font-size: 15px;
  border: 1px solid #1E88E5;
  background-color: #ffffff;
  color: #1E88E5;
  border-radius: 5px;
  cursor: pointer;
}

.send-button:hover {
  background-color: #1E88E5;
  color: #ffffff;
}

.action-popup {
  width: calc(100% + 281px);
  height: 100%;
  position: absolute;
  top: 0;
  left: -281px;
  background: rgba(51, 51, 51, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.action-popup-main {
  width: 150px;
  height: 120px;
  background: #ffffff;
  z-index: 100;
  border-radius: 10px;
  overflow: hidden;
}

.action-popup-main .action-item {
  text-align: center;
  line-height: 40px;
  font-size: 15px;
  color: #262628;
  border-bottom: 1px solid #efefef;
  cursor: pointer;
}

.image-preview {
  max-width: 750px;
  max-height: 500px;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9998;
}

.image-preview img {
  max-width: 750px;
  max-height: 500px;
}

.image-preview .close {
  font-size: 50px;
  line-height: 24px;
  cursor: pointer;
  color: #FFFFFF;
  position: absolute;
  top: 10px;
  right: 5px;
  z-index: 1002;
}

@media screen and (max-width: 700px) {
  .emoji-box {
    width: 310px;
  }

  .chat-container {
    position: fixed;
    z-index: 999;
    background: #FFFFFF;
    width: 100%;
    height: 100%;
  }

  .chat-title img {
    display: none;
  }

  .chat-name {
    font-size: 17px;
  }

  .return {
    display: inline;
  }

  .chat-footer {
    height: auto;
  }

  .action-delete div {
    display: none;
  }

  .delete-btn {
    margin: 15px 0 15px 0;
  }

  .action-popup {
    width: 100%;
    left: 0;
  }
}
</style>
